import "./TermsOfService.css";
import BackgroundImage from "../../assets/img/background-login.png";
import { Link } from "react-router-dom";

const LegalNotices = () => {
  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    maxHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBlock: "2rem",
    overflow: "hidden",
  };

  return (
    <div className="tos-container" style={style}>
      <div className="tos-content-container">
        <div className="tos-p-container">
          <h1 className="tos-title">Mentions légales</h1>
          <p>En vigueur au 09/07/2024</p>
          <p>
            Conformément aux dispositions des Articles 6-III et 19 de la Loi
            n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
            numérique, dite L.C.E.N., il est porté à la connaissance des
            utilisateurs et visiteurs, ci-après l"<strong>Utilisateur</strong>",
            du site <Link to="/">https://app.safeeat.fr</Link> , ci-après le "
            <strong>Site</strong>", les présentes mentions légales.
          </p>
          <p>
            La connexion et la navigation sur le Site par l’Utilisateur implique
            acceptation intégrale et sans réserve des présentes mentions
            légales.
          </p>
          <p>
            Ces dernières sont accessibles sur le Site à la rubrique «{" "}
            <strong>
              <Link to="/mentions-legales">Mentions légales</Link>
            </strong>{" "}
            ».
          </p>
          <p></p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title" id="article1">
            Article 1 : L'éditeur
          </h2>
          <p>
            L’édition et la direction de la publication du Site est assurée par
            Tom Lemelle, domiciliée 474 rue Gabriel Crochet, 76520,
            Franqueville-Saint-Pierre, dont le numéro de téléphone est
            0767338365, et l'adresse e-mail est{" "}
            <a href="mailto:tom.lemelle@gmail.com">tom.lemelle@gmail.com</a>.
          </p>
          <p>
            ci-après l'<strong>Editeur</strong>.
          </p>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 2 : L'hébergeur</h2>
          <p>
            L’hébergement de ce site est assuré par la société HOSTINGER
            INTERNATIONAL LTD, dont le siège social se situe 61 Lordou Vironos
            Street, 6023 Larnaca, Chypre, joignable par le moyen suivant :{" "}
            <a href="https://www.hostinger.fr/contact">
              https://www.hostinger.fr/contact
            </a>
            .
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 3 : Accès au site</h2>
          <p>
            Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de
            force majeure, interruption programmée ou non et pouvant découlant
            d’une nécessité de maintenance.
          </p>
          <p>
            En cas de modification, interruption ou suspension du Site,
            l'Editeur ne saurait être tenu responsable.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 4 : Collete des données</h2>
          <p>
            Le Site assure à l'Utilisateur une collecte et un traitement
            d'informations personnelles dans le respect de la vie privée
            conformément à la loi n°78-17 du 6 janvier 1978 relative à
            l'informatique, aux fichiers et aux libertés.
          </p>
          <p>
            En vertu de la loi Informatique et Libertés, en date du 6 janvier
            1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de
            suppression et d'opposition de ses données personnelles.
            L'Utilisateur exerce ce droit :
          </p>
          <ul>
            <li>par mail à l'adresse email Ilona.deschamps@safeeat.fr</li>
            <li>via son espace personnel</li>
          </ul>
          <p>
            Toute utilisation, reproduction, diffusion, commercialisation,
            modification de toute ou partie du Site, sans autorisation de
            l’Editeur est prohibée et pourra entraîner des actions et poursuites
            judiciaires telles que notamment prévues par le Code de la propriété
            intellectuelle et le Code civil.
          </p>
          <p>
            Pour plus d’informations, se reporter aux CGU du site
            Https://app.safeeat.fr accessible à la rubrique "CGU"
          </p>
          <p>
            Pour plus d’informations, se reporter aux CGV du site
            Https://app.safeeat.fr accessible à la rubrique "CGV"
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 5 : Responsabilité</h2>
          <p>
            Les sources des informations diffusées sur le site{" "}
            <Link to="/">https://app.safeeat.fr</Link> sont réputées fiables
            mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs
            ou d’omissions.
          </p>
          <p>
            Les informations communiquées sont présentées à titre indicatif et
            général sans valeur contractuelle. Malgré des mises à jour
            régulières, le site <Link to="/">https://app.safeeat.fr</Link> ne
            peut être tenu responsable de la modification des dispositions
            administratives et juridiques survenant après la publication. De
            même, le site ne peut être tenue responsable de l’utilisation et de
            l’interprétation de l’information contenue dans ce site.
          </p>
          <p>
            L'Utilisateur (restaurateur) s'assure de garder son mot de passe
            secret. Toute divulgation du mot de passe, quelle que soit sa forme,
            est interdite. Il assume les risques liés à l'utilisation de son
            identifiant et mot de passe. Le site décline toute responsabilité.
          </p>
          <p>
            Le site <Link to="/">https://app.safeeat.fr</Link> ne peut être tenu
            pour responsable d’éventuels virus qui pourraient infecter
            l’ordinateur ou tout matériel informatique de l’Internaute, suite à
            une utilisation, à l’accès, ou au téléchargement provenant de ce
            site.
          </p>
          <p>
            La responsabilité du site ne peut être engagée en cas de force
            majeure ou du fait imprévisible et insurmontable d'un tiers.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 6 : Liens hypertextes</h2>
          <p>
            Des liens hypertextes peuvent être présents sur le site.
            L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira
            du site <Link to="/">https://app.safeeat.fr</Link>. Ce dernier n’a
            pas de contrôle sur les pages web sur lesquelles aboutissent ces
            liens et ne saurait, en aucun cas, être responsable de leur contenu.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 7 : Cookies</h2>
          <p>
            L’Utilisateur est informé que lors de ses visites sur le site, un
            cookie peut s’installer automatiquement sur son logiciel de
            navigation.
          </p>
          <p>
            Les cookies sont de petits fichiers stockés temporairement sur le
            disque dur de l’ordinateur de l’Utilisateur par votre navigateur et
            qui sont nécessaires à l’utilisation du site{" "}
            <Link to="/">https://app.safeeat.fr</Link>. Les cookies ne
            contiennent pas d’information personnelle et ne peuvent pas être
            utilisés pour identifier quelqu’un. Un cookie contient un
            identifiant unique, généré aléatoirement et donc anonyme. Certains
            cookies expirent à la fin de la visite de l’Utilisateur, d’autres
            restent.
          </p>
          <p>
            L’information contenue dans les cookies est utilisée pour améliorer
            le site <Link to="/">https://app.safeeat.fr</Link>.
          </p>
          <p>En naviguant sur le site, L’Utilisateur les accepte.</p>
          <p>
            L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des
            paramètres figurant au sein de son logiciel de navigation.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 8 : Publication par l’Utilisateur (Restaurateur)
          </h2>
          <p>
            Le site permet aux membres (restaurateurs) de publier les contenus
            suivants : ajout de son restaurant (nom, email, téléphone,
            description, adresse, type de restauration, type de restaurant,
            couleurs de la charte graphique, logo), recettes du restaurant
            (image, nom, allergènes, description, prix), menus (nom, prix, une
            information sur le menu en question, les dates de disponibilités du
            menu, menu enfant, les recettes associées).
          </p>
          <p>
            Dans ses publications, le membre (restaurateur) s’engage à respecter
            les règles de la Netiquette (règles de bonne conduite de l’internet)
            et les règles de droit en vigueur.
          </p>
          <p>
            Le site peut exercer une modération sur les publications et se
            réserve le droit de refuser leur mise en ligne, sans avoir à s’en
            justifier auprès du membre (restaurateur).
          </p>
          <p>
            Le membre (restaurateur) reste titulaire de l’intégralité de ses
            droits de propriété intellectuelle. Mais en publiant une publication
            sur le site, il cède à la société éditrice le droit non exclusif et
            gratuit de représenter, reproduire, adapter, modifier, diffuser et
            distribuer sa publication, directement ou par un tiers autorisé,
            dans le monde entier, sur tout support (numérique ou physique), pour
            la durée de la propriété intellectuelle. Le Membre (restaurateur)
            cède notamment le droit d'utiliser sa publication sur internet et
            sur les réseaux de téléphonie mobile.
          </p>
          <p>
            La société éditrice s'engage à faire figurer le nom du membre
            (restaurateur) à proximité de chaque utilisation de sa publication.
          </p>
          <p>
            Tout contenu mis en ligne par l'Utilisateur (restaurateur) est de sa
            seule responsabilité. L'Utilisateur (restaurateur) s'engage à ne pas
            mettre en ligne de contenus pouvant porter atteinte aux intérêts de
            tierces personnes. Tout recours en justice engagé par un tiers lésé
            contre le site sera pris en charge par l'Utilisateur (restaurateur).
          </p>
          <p>
            Le contenu de l'Utilisateur (restaurateur) peut être à tout moment
            et pour n'importe quelle raison supprimé ou modifié par le site,
            sans préavis.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 9 : Droit applicable et juridiction compétente
          </h2>
          <p>
            La législation française s'applique au présent contrat. En cas
            d'absence de résolution amiable d'un litige né entre les parties,
            les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous
            pouvez joindre l’éditeur aux coordonnées inscrites à{" "}
            <a href="#article1">l’ARTICLE 1.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LegalNotices;
