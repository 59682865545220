import "./PublicRecipesList.css";
import { FC, useEffect, useState } from "react";
import {
  IRecipesListProps,
  IRestaurantRecipeCategories,
} from "../../../../../interface/Public/IPublic";
import { capitalize } from "../../../../../helpers/capitalize";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import PublicRecipeCard from "../PublicRecipeCard/PublicRecipeCard";

const PublicRecipeList: FC<IRecipesListProps> = ({
  recipes,
  primaryColor,
  secondaryColor,
  category,
}) => {
  const [activeCategory, setActiveCategory] = useState<string>(category);

  useEffect(() => {
    setActiveCategory(category);
  }, [category]);

  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleCategoryClick = (category: string) => {
    setActiveCategory(category === activeCategory ? "" : category);
  };

  const recipeCategories: IRestaurantRecipeCategories = {
    entrée: recipes.filter((recipe) => recipe.category === "Entrée"),
    plat: recipes.filter((recipe) => recipe.category === "Plat"),
    accompagnement: recipes.filter(
      (recipe) => recipe.category === "Accompagnement"
    ),
    dessert: recipes.filter((recipe) => recipe.category === "Dessert"),
  };

  const categoriesToShow =
    category === "tout"
      ? Object.entries(recipeCategories)
      : [
          [
            category,
            recipeCategories[category as keyof IRestaurantRecipeCategories],
          ],
        ];

  const capitalizeAndPluralize = (category: string, length: number): string => {
    return length > 1
      ? capitalize(category) + `s (${length})`
      : capitalize(category) + ` (${length})`;
  };

  return (
    <div
      className="public-recipes-list-container"
      onClick={handleStopPropagation}
    >
      {categoriesToShow.map(([cat, recs]) => (
        <div key={cat} className="public-recipes-category-list-container">
          <div
            className="public-recipes-category-list-title-container"
            onClick={() => handleCategoryClick(cat)}
          >
            {activeCategory === cat ? (
              <IoIosArrowDown size={24} color={primaryColor} />
            ) : (
              <IoIosArrowForward size={24} color={primaryColor} />
            )}
            <h3 style={{ color: primaryColor }}>
              {capitalizeAndPluralize(cat, recs.length)}
            </h3>
          </div>
          {activeCategory === cat && (
            <div className="public-recipes-content-card">
              {recs.map((recipe) => (
                <PublicRecipeCard
                  key={recipe.id}
                  imageUrl={
                    recipe.image
                      ? `${process.env.REACT_APP_BASE_URL}uploads/${recipe.image.breakpoints.thumbnail.name}`
                      : "https://placehold.co/80"
                  }
                  name={recipe.name}
                  description={recipe.description}
                  allergens={recipe.allergens}
                  updated_at={recipe.updated_at}
                  category={category}
                  primaryColor={primaryColor}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PublicRecipeList;
