import { Controller, FormProvider, useForm } from "react-hook-form";
import "./EditRestaurantComponent.css";
import { useState, useEffect } from "react";
import {
  FaEuroSign,
  FaStore,
  FaEnvelope,
  FaMapMarkerAlt,
  FaUtensils,
  FaInfoCircle,
  FaGlobe,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import colors from "../../../constants/colors";
import restaurantValidationRules from "../../../../helpers/Validators/restaurantValidationRules";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import {
  addNewRestaurantProfile,
  updateRestaurantProfile,
} from "../../../../store/Restaurant/Profile/Requests";
import { IEditRestaurantProfile } from "../../../../interface/IEditRestaurantProfile";
import useRestaurant from "../../../../hooks/useRestaurant";
import ImagePlaceholder from "../../../../assets/img/placeholder-logo.svg";
import restaurantTypeOptions from "../../../../models/restaurantTypeModel";
import GenericSelect from "../../../Reusables/InputField/GenericSelect";

const EditRestaurantComponent = () => {
  const methods = useForm<IRestaurantProfile>();

  const { restaurant } = useRestaurant();

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";
  const imagePreview =
    restaurant.logo && restaurant.logo.breakpoints
      ? imagePath + restaurant.logo.breakpoints.thumbnail.name
      : ImagePlaceholder;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const onSubmit = async (data: IRestaurantProfile) => {
    const formData = new FormData();

    if (data.name) formData.append("name", data.name);
    if (data.email) formData.append("email", data.email);
    if (data.description) formData.append("description", data.description);
    if (data.address) formData.append("address", data.address);
    if (data.tel) formData.append("tel", data.tel);

    if (data.restaurantType)
      formData.append("restaurant_type", data.restaurantType);
    if (data.restaurationType)
      formData.append("restauration_type", data.restaurationType);

    if (data.options) {
      formData.append("options", JSON.stringify(data.options));
    }

    if (data.logo) {
      formData.append("logo", data.logo);
    }

    const restaurantName = data.name || restaurant.name;
    const id = restaurant.id;

    handleAsyncResponse(
      dispatch,
      updateRestaurantProfile({ id, formData }),
      {
        successTitle: `Restaurant modifié`,
        successMessage: `Votre restaurant ${restaurantName} a été modifié ! 🙌`,
        successDescription: `Votre restaurant ${restaurantName} a été modifié ! 🙌`,
        navigateTo: "/pro/mon-restaurant",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Modifier votre restaurant</h1>
          <p>Remplissez ces champs pour modifier votre restaurant.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="logo">Le logo</label>
              <ImageInput
                name="logo"
                rules={restaurantValidationRules.image}
                defaultValue={restaurant.logo}
                imageUrl={imagePreview}
              />
            </div>
            <div className="form-recipe-grid">
              <div className="recipe-input-wrapper-container">
                <label htmlFor="name">
                  Le nom <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  name="name"
                  rules={restaurantValidationRules.name}
                  defaultValue={restaurant.name}
                  placeholder="Exemple : SafeEat"
                  id="name"
                  icon={FaStore}
                />
              </div>
              <div
                className="recipe-input-wrapper-container"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="email">
                  L'email <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  type="email"
                  name="email"
                  rules={restaurantValidationRules.email}
                  defaultValue={restaurant.email}
                  placeholder="Exemple : info@safeeat.fr"
                  id="name"
                  icon={FaEnvelope}
                />
              </div>
            </div>
            <div className="form-recipe-grid">
              <div className="recipe-input-wrapper-container">
                <label htmlFor="address">
                  L'adresse <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  type="address"
                  name="address"
                  rules={restaurantValidationRules.address}
                  defaultValue={restaurant.address}
                  placeholder="Exemple : 21 rue SafeEat, 76000 Rouen"
                  id="address"
                  icon={FaMapMarkerAlt}
                />
              </div>
              <div className="recipe-input-wrapper-container">
                <label htmlFor="tel">
                  Le téléphone <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  name="tel"
                  rules={restaurantValidationRules.tel}
                  defaultValue={restaurant.tel}
                  placeholder="Exemple : 07 08 09 04 05"
                  id="price"
                  icon={FaEuroSign}
                  isFlex1={false}
                />
              </div>
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="name">La description</label>
              <GenericInput
                name="description"
                rules={restaurantValidationRules.description}
                placeholder="Exemple : La description de votre restaurant qui sera affiché sur l'application mobile"
                id="name"
                defaultValue={restaurant.description || ""}
                icon={FaInfoCircle}
              />
            </div>
            <div className="form-recipe-grid">
              <div className="recipe-input-wrapper-container">
                <label htmlFor="restaurantType">Type de restaurant</label>
                <GenericSelect
                  name="restaurantType"
                  rules={restaurantValidationRules.restaurantType}
                  options={restaurantTypeOptions}
                  category={"catégorie"}
                  defaultValue={restaurant.restaurantType || ""}
                  Icon={FaUtensils}
                />
              </div>
              <div className="recipe-input-wrapper-container">
                <label htmlFor="restaurationType">Spécialiaté culinaire</label>
                <GenericInput
                  name="restaurationType"
                  rules={restaurantValidationRules.restaurationType}
                  placeholder="Exemple : Française"
                  id="restaurationType"
                  defaultValue={restaurant.restaurationType || ""}
                  icon={FaGlobe}
                />
              </div>
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="options">
                Options de personnalisation de la carte
              </label>
              <div
                className="form-input-color-field-container"
                style={{ flex: 1 }}
              >
                <Controller
                  name="options.primaryColor"
                  control={methods.control}
                  defaultValue={restaurant.options.primaryColor || colors.green}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="color"
                      className="color-picker"
                      defaultValue={colors.green}
                    />
                  )}
                />

                <Controller
                  name="options.secondaryColor"
                  control={methods.control}
                  defaultValue={
                    restaurant.options.secondaryColor || colors.brown
                  }
                  render={({ field }) => (
                    <input
                      {...field}
                      type="color"
                      defaultValue={colors.brown}
                      className="color-picker"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Modifier le restaurant
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default EditRestaurantComponent;
