import "./Restaurants.css";
import { useDispatch, useSelector } from "react-redux";
import SearchComponent from "../../../layout/MenusAndRecipesLayout/Search/SearchComponent";
import { selectAdminRestaurants } from "../../../store/Admin/Restaurants/AdminRestaurants";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../interface/Types/AppDispatch";
import { fetchAllRestaurantsForAdmin } from "../../../store/Admin/Restaurants/Requests";
import { IoIosArrowForward } from "react-icons/io";
import { IRestaurantProfile } from "../../../interface/IRestaurantProfile";
import { useNavigate } from "react-router-dom";
import FilterSelect from "../../Reusables/FilterSelect";

const Restaurants = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const restaurants = useSelector(selectAdminRestaurants);

  const [restaurantsData, setRestaurantsData] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);

  useEffect(() => {
    dispatch(fetchAllRestaurantsForAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (restaurants) {
      const restaurantObjects = restaurants.map((restaurant) => ({
        name: restaurant.name,
        address: restaurant.address,
        restaurantType: restaurant.restaurantType,
        restaurationType: restaurant.restaurationType,
        qrcode: restaurant.qrcode,
      }));
      setRestaurantsData(restaurantObjects);
      setFilteredRestaurants(restaurantObjects);
    }
  }, [restaurants]);

  const handleNavigateToRestaurant = (restaurant: IRestaurantProfile) => {
    const restaurantName = restaurant.qrcode.slug.split("/")[1];
    navigate("/restaurants/" + restaurantName);
  };

  return (
    <div className="restaurants-list-container">
      <h1>Les restaurants partenaires de SafeEat</h1>
      <div className="restaurants-list-utilities-container">
        <SearchComponent
          items={restaurantsData}
          setFilteredItems={setFilteredRestaurants}
          placeholder="Chercher un restaurant par son nom"
          className="restaurant-list-searchbar"
        />
      </div>
      <div className="restaurants-list">
        {filteredRestaurants && filteredRestaurants.length !== 0 ? (
          filteredRestaurants.map((restaurant, index) => (
            <div key={index} className="restaurant-item-container">
              <div className="restaurant-item-left">
                <div>{restaurant.name}</div>
                <div>{restaurant.address}</div>
                <div className="restaurant-item-left-type">
                  <div>{restaurant.restaurantType}</div>
                  <div>{restaurant.restaurationType}</div>
                </div>
              </div>
              <div
                className="restaurant-item-right-container"
                onClick={() => handleNavigateToRestaurant(restaurant)}
              >
                <IoIosArrowForward />
              </div>
            </div>
          ))
        ) : (
          <div>Aucun restaurant trouvé pour cette recherche.</div>
        )}
      </div>
    </div>
  );
};

export default Restaurants;
