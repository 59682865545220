import "./TermsOfService.css";
import BackgroundImage from "../../assets/img/background-login.png";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    maxHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBlock: "2rem",
    overflow: "hidden",
  };

  return (
    <div className="tos-container" style={style}>
      <div className="tos-content-container">
        <div className="tos-p-container">
          <h1 className="tos-title">Politique de confidentialité</h1>
          <p>En vigueur au 09/07/2024</p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Préambule</h2>
          <p>
            La présente politique de confidentialité a pour but d’informer les
            utilisateurs du site :
          </p>
          <ul>
            <li>
              Sur la manière dont sont collectées leurs données personnelles.
              Sont considérées comme des données personnelles, toute information
              permettant d’identifier un utilisateur. A ce titre, il peut s’agir
              : de ses noms et prénoms, de son âge, de son adresse postale ou
              email, de sa localisation ou encore de son adresse IP (liste
              non-exhaustive) ;
            </li>
            <li>Sur les droits dont ils disposent concernant ces données ; </li>
            <li>
              Sur la personne responsable du traitement des données à caractère
              personnel collectées et traitées ;
            </li>
            <li>Sur les destinataires de ces données personnelles ;</li>
            <li>Sur la politique du site en matière de cookies.</li>
          </ul>
          <p>
            Cette politique complète les mentions légales et les Conditions
            Générales d’Utilisation consultables par les utilisateurs à
            l’adresse suivante :{" "}
            <Link to={"/conditions-generales-d-utilisation"}>
              Conditions générales d'utilisations
            </Link>
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Principes relatifs à la collecte et au traitement des données
            personnelles
          </h2>
          <p>
            Conformément à l’article 5 du Règlement européen 2016/679, les
            données à caractère personnel sont :
          </p>
          <ul>
            <li>
              Traitées de manière licite, loyale et transparente au regard de la
              personne concernée ;
            </li>
            <li>
              Collectées pour des finalités déterminées (cf. Article 3.1 des
              présentes), explicites et légitimes, et ne pas être traitées
              ultérieurement d'une manière incompatible avec ces finalités ;
            </li>
            <li>
              Adéquates, pertinentes et limitées à ce qui est nécessaire au
              regard des finalités pour lesquelles elles sont traitées ;
            </li>
            <li>
              Exactes et, si nécessaire, tenues à jour. Toutes les mesures
              raisonnables doivent être prises pour que les données à caractère
              personnel qui sont inexactes, eu égard aux finalités pour
              lesquelles elles sont traitées, soient effacées ou rectifiées sans
              tarder ;
            </li>
            <li>
              Conservées sous une forme permettant l'identification des
              personnes concernées pendant une durée n'excédant pas celle
              nécessaire au regard des finalités pour lesquelles elles sont
              traitées ;
            </li>
            <li>
              Traitées de façon à garantir une sécurité appropriée des données
              collectées, y compris la protection contre le traitement non
              autorisé ou illicite et contre la perte, la destruction ou les
              dégâts d'origine accidentelle, à l'aide de mesures techniques ou
              organisationnelles appropriées.
            </li>
          </ul>
          <p>
            Le traitement n'est licite que si, et dans la mesure où, au moins
            une des conditions suivantes est remplie :
          </p>
          <ul>
            <li>
              La personne concernée a consenti au traitement de ses données à
              caractère personnel pour une ou plusieurs finalités spécifiques ;
            </li>
            <li>
              Le traitement est nécessaire à l'exécution d'un contrat auquel la
              personne concernée est partie ou à l'exécution de mesures
              précontractuelles prises à la demande de celle-ci ;
            </li>
            <li>
              Le traitement est nécessaire au respect d'une obligation légale à
              laquelle le responsable du traitement est soumis ;
            </li>
            <li>
              Le traitement est nécessaire à la sauvegarde des intérêts vitaux
              de la personne concernée ou d'une autre personne physique ;
            </li>
            <li>
              Le traitement est nécessaire à l'exécution d'une mission d'intérêt
              public ou relevant de l'exercice de l'autorité publique dont est
              investi le responsable du traitement ;
            </li>
            <li>
              Le traitement est nécessaire aux fins des intérêts légitimes
              poursuivis par le responsable du traitement ou par un tiers, à
              moins que ne prévalent les intérêts ou les libertés et droits
              fondamentaux de la personne concernée qui exigent une protection
              des données à caractère personnel, notamment lorsque la personne
              concernée est un enfant.
            </li>
          </ul>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Données à caractère personnel collectées et traitées dans le cadre
            de la navigation sur le site
          </h2>
          <p className="sub-article">
            <strong>Article 3.1 : Données collectées</strong>
          </p>
          <p>
            Les données personnelles collectées dans le cadre de notre activité
            sont les suivantes :
          </p>
          <p>Pour le compte Utilisateur (restaurateur) :</p>
          <ul>
            <li>Nom, prénom, e-mail, numéro de téléphone, adresse, photos.</li>
          </ul>
          <p>Pour le profil du Restaurant :</p>
          <ul>
            <li>
              Logo, nom du restaurant, adresse du restaurant, e-mail, numéro de
              téléphone, description du restaurant, type de restauration,
              couleurs de la charte graphique du restaurant.
            </li>
          </ul>
          <p>Pour les recettes du restaurant :</p>
          <ul>
            <li>
              Nom des recettes, description, photo, prix, allergènes, régime
              alimentaire spécifique (à venir).
            </li>
          </ul>
          <p>Pour les menus du restaurant :</p>
          <ul>
            <li>
              Nom, les recettes associées, prix, note liée au menu,
              dates/horaires de disponibilité, type de menu (enfant).
            </li>
          </ul>
          <p>
            La collecte et le traitement de ces données répond à la (aux)
            finalité(s) suivante(s) :
          </p>
          <ul>
            <li>
              Les données du compte utilisateur sont collectées à des fins de
              gestion des contrats, gestion de l’espace client.
            </li>
            <li>
              Les données du restaurant sont collectées afin de réaliser des
              statistiques, suivre la qualité des services, informations des
              utilisateurs (clients du restaurant)
            </li>
            <li>
              Les données concernant les recettes et les menus sont collectées
              afin de permettre de transmettre l’information à l’utilisateur
              (client du restaurant) sur la carte des menus et la carte
              allergènes ainsi que pour faire fonctionner le filtre dynamique.
            </li>
          </ul>
          <p className="sub-article">
            <strong>Article 3.2 : Mode de collecte des données</strong>
          </p>
          <p>
            Lorsque vous utilisez notre site, sont automatiquement collectées
            les données suivantes :
          </p>
          <ul>
            <li>Aucune donnée n’est automatiquement collectée.</li>
          </ul>
          <p>
            D’autres données personnelles sont collectées lorsque vous effectuez
            les opérations suivantes sur la plateforme :
          </p>
          <ul>
            <li>
              Données du compte utilisateur collectées lorsque ce dernier se
              crée un compte et un profil utilisateur.
            </li>
            <li>
              Données du compte restaurateur collectées lorsque l’utilisateur
              crée ou modifie le profil de son restaurant
            </li>
            <li>
              Les données liées aux recettes et menus sont collectées lorsque
              des informations concernant les recettes ou les menus sont
              ajoutées et/ou modifiées.
            </li>
          </ul>
          <p>
            Elles sont conservées par le responsable du traitement dans des
            conditions raisonnables de sécurité, pour une durée de : 3 ans en
            cas d’inactivité.
          </p>
          <p>
            La société est susceptible de conserver certaines données à
            caractère personnel au-delà des délais annoncés ci-dessus afin de
            remplir ses obligations légales ou réglementaires.
          </p>
          <p className="sub-article">
            <strong>Article 3.3 : Hébergement de données</strong>
          </p>
          <p>
            Le site <Link to="/">https://app.safeeat.fr</Link> est hébergé par :
          </p>
          <p>
            L’hébergement de ce site est assuré par la société HOSTINGER
            INTERNATIONAL LTD, dont le siège social se situe 61 Lordou Vironos
            Street, 6023 Larnaca, Chypre, joignable par le moyen suivant :
            <a href="https://www.hostinger.fr/contact" target="_blank">
              https://www.hostinger.fr/contact
            </a>
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 4 : Responsable du traitement des données et délégué à la
            protection des données
          </h2>
          <p className="sub-article">
            <strong>
              Article 4.1 : Le responsable du traitement des données
            </strong>
          </p>
          <p>
            Les données à caractère personnelles sont collectées par SafeEat,
            SAS au capital de 1 000 euros, dont le numéro d’immatriculation est
            le 930 530 233.
          </p>
          <p>
            Le responsable du traitement des données à caractère personnel peut
            être contacté de la manière suivante :
          </p>
          <ul>
            <li>
              Par courrier à l’adresse : 474 rue Gabriel Crochet, 76520,
              Franqueville-Saint-Pierre.
            </li>
            <li>
              Par téléphone, au <a href="tel:0630536229">06 30 53 62 29</a> ;
            </li>
            <li>
              Par mail :{" "}
              <a href="mailto:ilona.deschamps@safeeat.fr">
                Ilona.deschamps@safeeat.fr
              </a>
              .
            </li>
          </ul>
          <p className="sub-article">
            <strong>
              Article 4.2 : Le délégué à la protection des données
            </strong>
          </p>
          <p>
            Le délégué à la protection des données de l’entreprise ou du
            responsable est :
          </p>
          <ul>
            <li>
              Tom Lemelle, 474 rue Gabriel Crochet, 07 67 33 83 65,
              tom.lemelle@gmail.com
            </li>
          </ul>
          <p>
            Si vous estimez, après nous avoir contactés, que vos droits
            “Informatique et Libertés”, ne sont pas respectés, vous pouvez
            adresser une information à la CNIL.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 5 : Les droits de l'utilisateur en matière de collecte et de
            traitement des données
          </h2>
          <p>
            Tout utilisateur concerné par le traitement de ses données
            personnelles peut se prévaloir des droits suivants, en application
            du{" "}
            <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees">
              règlement européen 2016/679
            </a>{" "}
            et de la Loi Informatique et Liberté (
            <a href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000886460/#:~:text=L'informatique%20doit%20%C3%AAtre%20au,aux%20libert%C3%A9s%20individuelles%20ou%20publiques.">
              Loi 78-17 du 6 janvier 1978
            </a>
            ) :
          </p>
          <ul>
            <li>
              Droit d’accès, de rectification et droit à l’effacement des
              données (posés respectivement aux articles 15, 16 et 17 du RGPD) ;
            </li>
            <li>Droit à la portabilité des données (article 20 du RGPD) ;</li>
            <li>
              Droit à la limitation (article 18 du RGPD) et à l’opposition du
              traitement des données (article 21 du RGPD) ;
            </li>
            <li>
              Droit de ne pas faire l’objet d’une décision fondée exclusivement
              sur un procédé automatisé ;
            </li>
            <li>Droit de déterminer le sort des données après la mort ;</li>
            <li>
              Droit de saisir l’autorité de contrôle compétente (article 77 du
              RGPD).
            </li>
            <li>
              Pour exercer vos droits, veuillez adresser votre courrier à{" "}
              <i>[Nom et adresse de l’entreprise]</i> ou par mail à{" "}
              <i>
                [Insérer l’adresse e-mail du Délégué à la protection des
                données]
              </i>
            </li>
          </ul>
          <p>
            Afin que le responsable du traitement des données puisse faire droit
            à sa demande, l’utilisateur peut être tenu de lui communiquer
            certaines informations telles que : ses noms et prénoms, son adresse
            e-mail ainsi que son numéro de compte, d’espace personnel ou
            d’abonné.
          </p>
          <p>
            Consultez le site cnil.fr pour plus d’informations sur vos droits.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 6 : Conditions de modification de la politique de
            confidentialité
          </h2>
          <p>
            L’éditeur du site se réserve le droit de pouvoir modifier la
            présente Politique à tout moment afin d’assurer aux utilisateurs du
            site sa conformité avec le droit en vigueur.
          </p>
          <p>
            Les éventuelles modifications ne sauraient avoir d’incidence sur les
            achats antérieurement effectués sur le site, lesquels restent soumis
            à la Politique en vigueur au moment de l’achat et telle qu’acceptée
            par l’utilisateur lors de la validation de l’achat.
          </p>
          <p>
            L’utilisateur est invité à prendre connaissance de cette Politique à
            chaque fois qu’il utilise nos services, sans qu’il soit nécessaire
            de l’en prévenir formellement.
          </p>
          <p>
            La présente politique, éditée le <i>[date de mise en ligne]</i>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
