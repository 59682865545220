import { FC, useState } from "react";
import "./PublicRecipeCard.css";
import { IPublicRecipeCard } from "../../../../../interface/Public/IPublic";
import { formatDate } from "../../../../../helpers/getDate";
import { getTextColor } from "../../../../../helpers/color";
import PublicAllergensModal from "./PublicAllergensModal/PublicAllergensModal";

const PublicRecipeCard: FC<IPublicRecipeCard> = ({
  imageUrl,
  name,
  description,
  allergens,
  updated_at,
  category,
  primaryColor,
}) => {
  const [toggleAllergensModal, setToggleAllergensModal] =
    useState<boolean>(false);

  const textColor: "black" | "white" = getTextColor(primaryColor);

  const handleAllergensModal = () => {
    setToggleAllergensModal(!toggleAllergensModal);
  };

  return (
    <>
      <div className="public-recipe-card-container">
        <div className="public-recipe-card-img">
          <img src={imageUrl} alt={`Image de la recette ${name}`} />
        </div>
        <div className="public-recipe-card-informations">
          <div
            className="public-recipe-card-informations-title"
            style={{ color: primaryColor }}
          >
            {name}
          </div>
          <div className="public-recipe-card-informations-description">
            {description}
          </div>
          <div className="public-recipe-card-informations-bottom">
            <div
              className="public-recipe-card-informations-allergens-btn pointer"
              style={{ color: primaryColor }}
              onClick={handleAllergensModal}
            >
              Afficher les allergènes
            </div>
            <div
              className="public-recipe-card-informations-date"
              style={{ backgroundColor: primaryColor, color: textColor }}
            >
              <div>{formatDate(updated_at)}</div>
            </div>
          </div>
        </div>
      </div>
      {toggleAllergensModal && (
        <PublicAllergensModal
          toggleAllergensModal={toggleAllergensModal}
          setToggleAllergensModal={setToggleAllergensModal}
          allergens={allergens}
          category={category}
          name={name}
          primaryColor={primaryColor}
        />
      )}
    </>
  );
};

export default PublicRecipeCard;
