import "./PublicMenuRecipesList.css";
import { FC, useState } from "react";
import { IMenuRecipesListProps } from "../../../../../interface/Public/IPublic";
import { capitalize } from "../../../../../helpers/capitalize";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import PublicRecipeCard from "../PublicRecipeCard/PublicRecipeCard";

const PublicMenuRecipesList: FC<IMenuRecipesListProps> = ({
  starters,
  dishes,
  accompaniments,
  desserts,
  primaryColor,
  secondaryColor,
}) => {
  const [activeCategory, setActiveCategory] = useState<string>("");

  const categories = {
    entrée: starters,
    plat: dishes,
    accompagnement: accompaniments,
    dessert: desserts,
  };

  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleCategoryClick = (category: string) => {
    setActiveCategory(category === activeCategory ? "" : category);
  };

  const capitalizeAndPluralize = (category: string, length: number): string => {
    return length > 1
      ? capitalize(category) + `s (${length})`
      : capitalize(category) + ` (${length})`;
  };

  return (
    <div
      className="public-recipes-list-container"
      onClick={handleStopPropagation}
    >
      {Object.entries(categories).map(([category, recipes]) => (
        <div key={category} className="public-recipes-category-list-container">
          <div className="public-recipes-category-list-title-container">
            {activeCategory === category ? (
              <IoIosArrowDown size={24} color={primaryColor} />
            ) : (
              <IoIosArrowForward size={24} color={primaryColor} />
            )}
            <h3
              onClick={() => handleCategoryClick(category)}
              style={{ color: primaryColor }}
            >
              {capitalizeAndPluralize(category, recipes.length)}
            </h3>
          </div>
          {activeCategory === category && (
            <div className="public-recipes-content-card">
              {recipes.map((recipe) => (
                <PublicRecipeCard
                  key={recipe.id}
                  imageUrl={
                    recipe.image
                      ? `${process.env.REACT_APP_BASE_URL}uploads/${recipe.image.breakpoints.thumbnail.name}`
                      : "https://placehold.co/80"
                  }
                  name={recipe.name}
                  description={recipe.description}
                  allergens={recipe.allergens}
                  updated_at={recipe.updated_at}
                  category={category}
                  primaryColor={primaryColor}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PublicMenuRecipesList;
