import { useState, useEffect } from "react";
import { IRestaurantRecipe } from "../../interface/IRestaurantRecipe";
import { IPublicMenus } from "../../interface/Public/IPublic";

const useFilteredItems = (
  publicMenus: IPublicMenus[],
  restaurantRecipes: IRestaurantRecipe[],
  allergenFilterIds: string[],
  category: string
) => {
  const [filteredItems, setFilteredItems] = useState<
    IPublicMenus[] | IRestaurantRecipe[]
  >([]);

  useEffect(() => {
    const safeFilterByAllergens = (recipes: IRestaurantRecipe[]) => {
      return recipes.filter(
        (recipe) =>
          recipe.archived === true &&
          (!recipe.allergens ||
            !recipe.allergens.some((allergen) =>
              allergenFilterIds.includes(allergen.name)
            ))
      );
    };

    if (category === "menu") {
      const filteredMenus = publicMenus
        .filter((menu) => menu.archived === true)
        .map((menu) => ({
          ...menu,
          recipes: {
            starters: safeFilterByAllergens(menu.recipes.starters),
            dishes: safeFilterByAllergens(menu.recipes.dishes),
            accompaniments: safeFilterByAllergens(menu.recipes.accompaniments),
            desserts: safeFilterByAllergens(menu.recipes.desserts),
          },
        }))
        .filter(
          (menu) =>
            menu.recipes.starters.length > 0 ||
            menu.recipes.dishes.length > 0 ||
            menu.recipes.accompaniments.length > 0 ||
            menu.recipes.desserts.length > 0
        );

      setFilteredItems(filteredMenus);
    } else if (category === "tout") {
      const filteredRecipes = restaurantRecipes.filter(
        (recipe) =>
          recipe.archived === true &&
          (allergenFilterIds.length === 0 ||
            !recipe.allergens?.some((allergen) =>
              allergenFilterIds.includes(allergen.name)
            ))
      );

      setFilteredItems(filteredRecipes);
    } else {
      const filteredRecipes = restaurantRecipes.filter(
        (recipe) =>
          recipe.archived === true &&
          recipe.category.toLowerCase() === category.toLowerCase() &&
          (allergenFilterIds.length === 0 ||
            !recipe.allergens?.some((allergen) =>
              allergenFilterIds.includes(allergen.name)
            ))
      );

      setFilteredItems(filteredRecipes);
    }
  }, [publicMenus, restaurantRecipes, allergenFilterIds, category]);

  return filteredItems;
};

export default useFilteredItems;
